
import { Component, Vue, Watch } from "vue-property-decorator";
import i18n from "../../plugins/i18n";

import formatDate from "../../plugins/formatDate";

import SporteventService from "../../services/sportevent/SporteventService";
import Sportevent from "../../models/sportevent/Sportevent";
import moment from "moment";

@Component({
  filters: {
    formatDate,
  },
})
export default class SporteventsComponent extends Vue {
  // Loading
  public loading = true;
  // Table
  public items: Sportevent[] = [];
  public fields = [
    {
      key: "name",
      sortable: true,
      label: i18n.tc("labels.name"),
    },
    {
      key: "eventDate",
      sortable: true,
      label: i18n.tc("sportevent.eventDate"),
    },
    {
      key: "endDate",
      sortable: true,
      label: i18n.tc("sportevent.endDate"),
    },
    {
      key: "registrationDeadline",
      sortable: true,
      label: "Meldeschluss",
    },
    {
      key: "actions",
      sortable: false,
      label: "",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 1;
  public currentPage = 1;
  public hide_expired = true;

  showAllEvents(): void {
    this.hide_expired = this.hide_expired ? false : true;
    this.getAll();
  }

  async getAll(): Promise<void> {
    this.items = await SporteventService.getAll();
    this.items.sort((a, b) => (a.eventDate > b.eventDate ? -1 : 1));
    const compareFormat = "yyyyMMDD";
    const today = moment(new Date()).format(compareFormat);
    if (this.hide_expired) {
      this.items = this.items.filter(
        (sportevent) =>
          moment(sportevent.eventDate).format(compareFormat) >= today
      );
    }

    if (this.items) {
      this.totalRows = this.items.length;
    }
    this.loading = false;
  }

  mounted(): void {
    this.getAll();
  }

  async openSportevent(open_sportevent: Sportevent): Promise<void> {
    await SporteventService.clearAllStores();
    await SporteventService.saveSporteventToStore(new Sportevent());
    if (open_sportevent !== undefined && open_sportevent.id !== undefined) {
      const sportevent = await SporteventService.getWithApi(open_sportevent.id);
      await SporteventService.saveSporteventToStore(sportevent);
    }

    this.$router.push("Sportevent");
  }
}
